.navbar {
  padding: 10px 20px;
    height:12vh;
  
  }
  
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
  }
  
  .menu {
    display: flex;
  }
  
  .logout-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-left: 10px;
  
  }
  
  .logout-icon {
    color: #000;
    width: 50px;
    height: 50px;
  }
  img{
    height: 10vh;
    width: 10vw;
  }
/* Footer.css */
.footer {
    background-color: #009BDF;
    padding: 20px;
    color: #ffffff;
    text-align: center;
    font-size: 14px;
margin-top: 25vh;    

    
  }

.dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    height: 100vh;
    margin-top: 2vh;
  }
  
  .buttons-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  .status-message {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin: 25px;
    text-align: center;
    /* Additional styles as per your preference */
  }
  .profile-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #eee;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .profile-button.selected {
    background-color: #009BDF;
    color: white;
  }
  
  .api-response {
    width: 100%;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    border:3px solid black;
  }
  
  th,
  td {
    padding: 8px;
    text-align: left;
    border: 1px solid #000000;
  }
  
  th {
    background-color: #f2f2f2;
    border:3px solid black;

  }
  
  p {
    margin-bottom: 10px;
  }
  .credit-transaction {
    color: red;
  }
  
  .debit-transaction {
    color: green;
  }


  .account-form {
    width: 300px;
    margin: 0 auto;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    font-weight: bold;
  }
  
  .form-control {
    width: 100%;
    padding: 5px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.768); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  max-width: 400px;
  text-align: center;
  
}

.modal h2 {
  margin-top: 0;
}


.modal p {
  margin-bottom: 20px;
}

.modal button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  margin-right: 10px;
}

.modal button:last-child {
  margin-right: 0;
}

.modal button:hover {
  background-color: #0056b3;
}
/* Style the button group container */
.button-group {
  text-align: center;
  margin-top: 20px; /* Adjust the margin as needed */
}

/* Style the individual buttons */
.button-group a {
  display: inline-block;
  min-width: 150px; /* Set the minimum width to your desired size */
  min-height: 40px; /* Set the minimum height to your desired size */
  padding: 10px;
  margin: 5px;
  background-color: #007bff; /* Change the background color as desired */
  color: #fff; /* Text color */
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden;
  text-overflow: ellipsis; /* Add ellipsis for long text */
}

/* Hover effect */
.button-group a:hover {
  background-color: #0056b3; /* Change the background color on hover */
}

/* Optional: Add more styling for visited links */
.button-group a:visited {
  color: #fff;
}

/* Optional: Add more styling for active links */
.button-group a:active {
  background-color: #004088; /* Change the background color for active links */
}
